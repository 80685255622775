import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
   //mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '*', 
      //component: NotFoundComponent,
      component: () => import('@/views/dashboard/adm/Page404'),
      name: 'NotFound'
    },
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'adm/',
          component: () => import('@/views/dashboard/adm/Dashboard'),
        },
        
        // Pages
        {
          name: 'Usuário',
          path: 'adm/usuario',
          component: () => import('@/views/dashboard/adm/Usuario'),
        },

        {
          name: 'Cartao',
          path: 'adm/cartao',
          component: () => import('@/views/dashboard/adm/Cartao'),
        },

        {
          name: 'Carnê',
          path: 'adm/digital',
          component: () => import('@/views/dashboard/adm/Digital'),
        },

        {
          name: 'Aprovadas',
          path: 'adm/aprovadas',
          component: () => import('@/views/dashboard/adm/Aprovadas'),
        },

        {
          name: 'Analítico',
          path: 'adm/analitico',
          component: () => import('@/views/dashboard/adm/Analitico'),
        },


        {
          name: 'Sintético',
          path: 'adm/sintetico',
          component: () => import('@/views/dashboard/adm/Sintetico'),
        },

        {
          name: 'Vendas',
          path: 'adm/vendas',
          component: () => import('@/views/dashboard/adm/Vendas'),
        },

        {
          name: 'Register',
          path: 'adm/register',
          component: () => import('@/views/dashboard/adm/Register'),
        },

        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },

        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
      ],
      
      
    },
    {
      path: '/login',
      component: () => import('@/views/dashboard/Login'),
    },
    {
      path: '/restrito/atualizacao',
      component: () => import('@/views/restrito/Atualizacao'),
    },

  ],
})
