// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import JsonExcel from 'vue-json-excel'
import "./plugins/vuetify-mask.js";
import VueMask from 'v-mask';
Vue.use(VueMask);



Vue.config.productionTip = false

Vue.component('downloadExcel', JsonExcel)



router.beforeEach((to, from, next) => {
  if(to.path == '/adm/adm/'){
    router.push('/adm/');
  }
  

  if(to.path == '/adm/login'){
    router.push('/login');
  }
  
  if(to.path != '/login'){
      const user = JSON.parse(sessionStorage.getItem('user'))

      if(user == null){
        router.push('login');
      }else if(to.path == '/'){
        router.push('adm/');
      } 
      
  
  }


      next()
}),


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
